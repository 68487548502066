@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 300;
        src: url(./fonts/Quicksand_Book.otf);
    }

    @font-face {
        font-family: 'Montserrat-Light-Alt1';
        font-style: normal;
        font-weight: 300;
        src: url(./fonts/Montserrat-Light-Alt1.otf);
      }
  }

